import React from 'react';
import { Typography, Container, Grid, Card, CardContent, Box, Button} from '@mui/material';
import { Phone as PhoneIcon, LocationOn as LocationOnIcon, WhatsApp as WhatsAppIcon} from '@mui/icons-material';
import { useMediaQuery as useOrientationQuery } from 'react-responsive'; 
import Header from '../Header';
import Footer from '../Footer';

function ContactUs() {
  const phoneNumber = '05324534106';
  const whatsappLink = `https://wa.me/90${phoneNumber}`;

  // 1) Orientation sorgusu
  const isPortrait = useOrientationQuery({ query: '(orientation: portrait)' });

  return (
    <div>
      <Header />

      {/* 2) Container üst-alt boşluklar orientation'a göre */}
      <Container
        maxWidth="md"
        sx={{
          mt: isPortrait ? 3 : 5,
          mb: isPortrait ? 3 : 5,
          position: 'relative',
        }}
      >
        <Card>
          <CardContent>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                fontSize: isPortrait ? '1.8rem' : '2rem',
                fontWeight: 'bold',
              }}
            >
              İletişim
            </Typography>

            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center" mb={2}>
                  <PhoneIcon color="primary" sx={{ mr: 1 }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: isPortrait ? '1rem' : '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    Telefon:
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ ml: 4 }}>
                  {phoneNumber}
                </Typography>

                <Box display="flex" alignItems="center" mt={4} mb={2}>
                  <LocationOnIcon color="primary" sx={{ mr: 1 }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: isPortrait ? '1rem' : '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    Adres:
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ ml: 4 }}>
                 Kızılay, Ankara
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <iframe
                    title="Harita"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.668304809986!2d32.5965496!3d39.9865934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34f3c4e4f0c25%3A0xabcdef1234567890!2sYavuz%20Selim%20Mah.%20560%20Cadde%20No%3A2%2C%20Eryaman%2C%20Etimesgut%2C%20Ankara!5e0!3m2!1str!2str!4v1697058404773!5m2!1str!2str"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </Box>
              </Grid>
            </Grid>

            {/* WhatsApp Butonu */}
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Button
                variant="contained"
                color="success"
                size="large"
                startIcon={<WhatsAppIcon />}
                href={whatsappLink}
                target="_blank"
                sx={{
                  fontSize: isPortrait ? '0.9rem' : '1rem',
                  fontWeight: 'bold',
                }}
              >
                WhatsApp ile İletişime Geçin
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>

      <Footer />
    </div>
  );
}

export default ContactUs;
