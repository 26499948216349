import React from 'react';
import { Typography, Grid, Box, Container, IconButton, Card, CardContent } from '@mui/material';
import { Computer as ComputerIcon, Group as GroupIcon, Diamond as DiamondIcon, CheckCircleOutline as CheckCircleOutlineIcon} from '@mui/icons-material';
import { WhatsApp as WhatsAppIcon, Instagram as InstagramIcon, YouTube as YouTubeIcon, Phone as PhoneIcon } from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import { useMediaQuery as useOrientationQuery } from 'react-responsive';

import Header from '../components/Header';
import Footer from '../components/Footer';
import HeaderBanner from '../components/ui/Banner';
import PackagePromotion from '../components/paketler/PackagePromotion';
import HomeBlogSection from '../components/blog/BlogList';

const StyledCard = styled(Card)(() => ({
  minWidth: 275,
  boxShadow: 10,
  borderRadius: 3,
  color: '#fff',
  transition: 'transform 0.3s, box-shadow 0.3s',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '-3px 8px 23px -2px rgba(0,0,0,0.75)',
  },
}));

const StyledCardContent = styled(CardContent)(() => ({
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const TitleTypography = styled(Typography)(({ isPortrait }) => ({
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  fontSize: isPortrait ? '1.8rem' : '2rem',
}));

const SubtitleTypography = styled(Typography)(({ isPortrait }) => ({
  fontFamily: '"Roboto Condensed", sans-serif',
  fontWeight: 700,
  color: '#fff',
  textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  marginTop: 8,
  fontSize: isPortrait ? '1rem' : '1.25rem',
}));

function MainCard({ Icon, title, subtitle, bgColor, isPortrait }) {
  return (
    <StyledCard style={{ background: bgColor }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '10px',
          backgroundColor: 'rgba(255,255,255,0.3)',
        }}
      />
      <StyledCardContent sx={{ py: isPortrait ? 5 : 6 }}>
        <Icon
          sx={{
            fontSize: isPortrait ? 50 : 60,
            mb: 2,
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
          }}
        />
        <TitleTypography isPortrait={isPortrait} variant="h4" component="div">
          {title}
        </TitleTypography>
        <SubtitleTypography isPortrait={isPortrait} variant="subtitle1">
          {subtitle}
        </SubtitleTypography>
      </StyledCardContent>
    </StyledCard>
  );
}

function Home() {
  const isPortrait = useOrientationQuery({ query: '(orientation: portrait)' });

  const phoneNumber = '5324534106';
  const whatsappLink = `https://wa.me/90${phoneNumber}`;
  const instagramLink = 'https://www.instagram.com/okumakshizliokuma?igsh=d2NwNXkxcGk1MWJs&utm_source=qr';
  const youtubeLink = 'https://www.youtube.com/@OKUMAKS';

  return (
    <div>
      <Header />
      <HeaderBanner />

      <Box
        sx={{
          flexGrow: 1,
          py: isPortrait ? 5 : 8,
          backgroundColor: '#f0f2f5',
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              mb: 2,
              color: '#333',
              fontSize: isPortrait ? '2rem' : '2.5rem',
            }}
          >
            Bizimle Neler Başarabilirsiniz?
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                mb: isPortrait ? 4 : 6,
                color: '#555',
                fontWeight: 500,
                fontStyle: 'italic',
                letterSpacing: '0.5px',
                lineHeight: 1.6,
                background: 'linear-gradient(90deg, #ece9e6, #ffffff)',
                display: 'inline-block',
                padding: isPortrait ? '6px 12px' : '8px 16px',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                fontSize: isPortrait ? '1rem' : '1.1rem',
              }}
            >
              Seri Okuma, Okuduğunu Anlama, Paragraf Teknikleri ve Hafıza Teknikleri
            </Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <MainCard
                Icon={ComputerIcon}
                title="71k+"
                subtitle="Eğitim Kursu Sunulan"
                bgColor="linear-gradient(135deg, #7b1fa2, #9c27b0)"
                isPortrait={isPortrait}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MainCard
                Icon={GroupIcon}
                title="50k+"
                subtitle="Öğrenci Memnuniyeti"
                bgColor="linear-gradient(135deg, #00796b, #009688)"
                isPortrait={isPortrait}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MainCard
                Icon={DiamondIcon}
                title="200+"
                subtitle="Deneyimli Öğretmen"
                bgColor="linear-gradient(135deg, #c2185b, #e91e63)"
                isPortrait={isPortrait}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MainCard
                Icon={CheckCircleOutlineIcon}
                title="95%"
                subtitle="Başarı Oranı"
                bgColor="linear-gradient(135deg, #fbc02d, #ffc107)"
                isPortrait={isPortrait}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <PackagePromotion />
      <HomeBlogSection />

      {/* Sabit (fixed) sosyal medya ikonları + Telefon */}
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          right: isPortrait ? 12 : 16,
          transform: 'translateY(-50%)',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          gap: isPortrait ? 1.5 : 2,
          backgroundColor: 'rgba(255,255,255,0.8)',
          borderRadius: '16px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          p: isPortrait ? 1.5 : 2,
        }}
      >
        <IconButton
          href={instagramLink}
          target="_blank"
          sx={{
            width: isPortrait ? 50 : 56,
            height: isPortrait ? 50 : 56,
            bgcolor: '#C13584',
            color: '#fff',
            borderRadius: '50%',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.1)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            },
          }}
        >
          <InstagramIcon sx={{ fontSize: isPortrait ? 24 : 28 }} />
        </IconButton>

        <IconButton
          href={youtubeLink}
          target="_blank"
          sx={{
            width: isPortrait ? 50 : 56,
            height: isPortrait ? 50 : 56,
            bgcolor: '#FF0000',
            color: '#fff',
            borderRadius: '50%',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.1)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            },
          }}
        >
          <YouTubeIcon sx={{ fontSize: isPortrait ? 24 : 28 }} />
        </IconButton>

        <IconButton
          href={whatsappLink}
          target="_blank"
          sx={{
            width: isPortrait ? 50 : 56,
            height: isPortrait ? 50 : 56,
            bgcolor: '#25D366',
            color: '#fff',
            borderRadius: '50%',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.1)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            },
          }}
        >
          <WhatsAppIcon sx={{ fontSize: isPortrait ? 24 : 28 }} />
        </IconButton>

        {/* 4. BUTON: Telefonla Ara */}
        <IconButton
          href="tel:+905324534106" // Buraya istediğiniz numarayı ekleyin
          sx={{
            width: isPortrait ? 50 : 56,
            height: isPortrait ? 50 : 56,
            bgcolor: '#00897b', // İstediğiniz renk
            color: '#fff',
            borderRadius: '50%',
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.1)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
            },
          }}
        >
          <PhoneIcon sx={{ fontSize: isPortrait ? 24 : 28 }} />
        </IconButton>
      </Box>

      <Footer />
    </div>
  );
}

export default Home;
